import { Routes } from '@angular/router';

import { canActivateApp } from './core/guards/auth.guard';
import { canActivateRoute } from '@core/guards/permission.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadComponent: () => import('./feature/auth/auth.component').then((m) => m.AuthComponent),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./feature/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [canActivateApp],
  },
  {
    path: 'partner',
    loadChildren: () => import('./feature/partner/partner.module').then((m) => m.PartnerModule),
    canActivate: [canActivateRoute],
    data: { scope: 'partner' },
  },
  {
    path: 'partner-functions',
    loadChildren: () => import('./feature/partner-functions/partner-functions.module').then((m) => m.PartnerFunctionsModule),
    canActivate: [canActivateRoute],
    data: { scope: 'partner' },
  },
  {
    path: 'integration-log',
    loadChildren: () => import('./feature/integration-log/integration-log.module').then((m) => m.IntegrationLogModule),
    canActivate: [canActivateRoute],
    data: { scope: 'integration-log' },
  },
  {
    path: 'transactions',
    loadChildren: () => import('./feature/transaction/transaction.module').then((m) => m.TransactionModule),
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
];
