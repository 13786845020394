<app-toolbar></app-toolbar>
<mat-sidenav-container autosize>
  <mat-sidenav opened mode="side">
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <div class="p-4">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>

