import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@src/environments/environment';

export function apiInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const auth = inject(AuthService);
  const snack = inject(MatSnackBar);

  const config: any = {
    url: environment.apiHost === 'mock' ? buildMockUrl(req) : req.url,
  };
  if (!req.url.includes('s3.eu-west-1.amazonaws.com')) {
    config.headers = req.headers.set('Authorization', auth.token);
  }
  const authReq = req.clone(config);

  return next(authReq).pipe(tap({
    error: (error) => {
      if (error.status === 401) {
        auth.login();
      } else {
        const message = error.error.message || error.message;
        snack.open(message, 'Dismiss');
      }
    }
  }));
}

const buildMockUrl = (request: HttpRequest<unknown>) => {
  const [, endpoint, id] = request.url.split('/');
  const method = request.method.toLowerCase();
  const name = id ? `${endpoint}-${method}-id` : `${endpoint}-${method}`;
  return `/assets/mock/${name}.json`;
};
